import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import InstaLoader from "./InstaLoader";

const validationSchema = Yup.object({
  offlineConfirmation: Yup.string().required("Please select yes or no"),
  durationConfirmation: Yup.string().required("Please select yes or no"),
  batchTiming: Yup.string().required("Please select your preferable batch timing"),
  currentOccupation: Yup.array().min(1, "Select at least one occupation"),
  fullName: Yup.string().required("Please enter your full name"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Please enter your contact number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email"),
  streetAddress: Yup.string().required("Please enter your street address"),
  whyAOI: Yup.string().when('step', {
    is: 4, // Step where the field is visible
    then: Yup.string().required("Please specify what triggered your interest"),
  }),
});

const MultiStepAoiform = () => {
  const [step, setStep] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const navigate = useNavigate();

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => setStep(step - 1);

  return (
    <div className="multistepform-container">
      <h2>Art of Influence (AOI) Multi-Step Form</h2>

      <Formik
        initialValues={{
          offlineConfirmation: "",
          durationConfirmation: "",
          batchTiming: "",
          currentOccupation: [],
          fullName: "",
          phoneNumber: "",
          email: "",
          streetAddress: "",
          whyAOI: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmissionStatus("");
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}/instaForm/aoi`, values)
            .then((response) => {
              console.log('Data submitted:', response);
              setSubmissionStatus("success");
              navigate('/instaAOIthankyou');
            })
            .catch((error) => {
              console.error('Error submitting data:', error);
              setSubmissionStatus("failed");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="instaform">
            {step === 1 && (
              <div className="stepform">
                <h3>AOI FORM</h3>

                <div className="form-field">
                  <label>Full Name</label>
                  <Field type="text" name="fullName" className="field" />
                  <ErrorMessage name="fullName" component="div" className="error" />
                </div>

                <div className="form-field">
                  <label>Phone Number</label>
                  <Field type="text" name="phoneNumber" className="field" />
                  <ErrorMessage name="phoneNumber" component="div" className="error" />
                </div>

                <div className="form-field">
                  <label>Email</label>
                  <Field type="email" name="email" className="field" />
                  <ErrorMessage name="email" component="div" className="error" />
                </div>

                <div className="form-field">
                  <label>Street Address</label>
                  <Field type="text" name="streetAddress" className="field" />
                  <ErrorMessage name="streetAddress" component="div" className="error" />
                </div>
                <div className="singlenextinstabtn">
                  <button
                    type="button"
                    onClick={() => nextStep()}
                    disabled={isSubmitting}
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="stepform">
                <h3>AOI FORM</h3>

                <div className="form-field">
                  <label>
                    Are you interested in this offline program in Himayat Nagar, Hyderabad?
                  </label>
                  <div className="readiofeild">
                    <Field type="radio" name="offlineConfirmation" value="Yes" className="readi-btn" />
                    <label className="readibtn-label">Yes</label>
                    <Field type="radio" name="offlineConfirmation" value="No" className="readi-btn" />
                    <label className="readibtn-label">No</label>
                  </div>
                  <ErrorMessage name="offlineConfirmation" component="div" className="error" />
                </div>

                <div className="form-field">
                  <label>Are you ready for 60+ days of 3 hours per day?</label>
                  <div className="readiofeild">
                    <Field type="radio" name="durationConfirmation" value="Yes" className="readi-btn" />
                    <label className="readibtn-label">Yes</label>
                    <Field type="radio" name="durationConfirmation" value="No" className="readi-btn" />
                    <label className="readibtn-label">No</label>
                  </div>
                  <ErrorMessage name="durationConfirmation" component="div" className="error" />
                </div>

                <div className="form-field">
                  <label>Preferred batch timing</label>
                  <Field as="select" name="batchTiming" className="field custom-select">
                    <option value="">Select Batch Timing</option>
                    <option value="10 AM to 1 PM">10 AM to 1 PM</option>
                    <option value="6 PM to 9 PM">6 PM to 9 PM</option>
                  </Field>
                  <ErrorMessage name="batchTiming" component="div" className="error" />
                </div>

                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" />
                    Previous
                  </button>
                  <button type="button" onClick={() => nextStep()} disabled={isSubmitting}>
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="stepform">
                <h3>AOI FORM</h3>

                <div className="form-field">
                  <label>What is your current occupation?</label>
                  <div role="group" aria-labelledby="checkbox-group" className="slectinstaocc">
                    <label>
                      <Field type="checkbox" name="currentOccupation" value="Student" className="checkfeildinsta" />
                      Student
                    </label>
                    <label>
                      <Field type="checkbox" name="currentOccupation" value="Working Professional" className="checkfeildinsta" />
                      Working Professional
                    </label>
                    <label>
                      <Field type="checkbox" name="currentOccupation" value="Business Owner" className="checkfeildinsta" />
                      Business Owner
                    </label>
                    <label>
                      <Field type="checkbox" name="currentOccupation" value="Have a startup idea" className="checkfeildinsta" />
                      Have a startup idea
                    </label>
                  </div>
                  <ErrorMessage name="currentOccupation" component="div" className="error" />
                </div>

                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" />
                    Previous
                  </button>
                  <button type="button" onClick={() => nextStep()} disabled={isSubmitting}>
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="stepform">
                <h3>AOI FORM</h3>

                <div className="form-field">
                  <label>What triggered your interest in Art of Influence?</label>
                  <Field as="textarea" name="whyAOI" className="field" id="aoitextarea" />
                  <ErrorMessage name="whyAOI" component="div" className="error" />
                </div>

                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" />
                    Previous
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <InstaLoader /> : 
                      submissionStatus === "failed" 
                        ? "Failed to Submit" 
                        : submissionStatus === "success" 
                        ? "Submitted Form" 
                        : "Submit"}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepAoiform;
