import React from "react";

const InstaAoiThankyou = () => {
  return (
    <div className="instathanks">
     <p> thanks for filling the form we will reach out to You soon...</p>
    </div>
  );
};

export default InstaAoiThankyou;
