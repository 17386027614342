import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import "./instaform.css";
import { useNavigate } from "react-router-dom";
import { colors } from "@mui/material";
import InstaLoader from "./InstaLoader";



const validationSchema = Yup.object({
  modeOfLearning: Yup.string().required("Please select yes or no"),
  durationConfirmation: Yup.string().required("Please select yes or no"),
  feeCommitment: Yup.string().min(1, "Select at least one option"),
  fullName: Yup.string().required("Please enter your full name"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Please enter your contact number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email"),
  streetAddress: Yup.string().required("Please enter your street address"),
  whyDmx: Yup.string().required("Please specify your interest"),
});

const MultiStepDmxform = () => {
  const [step, setStep] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState(""); // Success/Failure
  const navigate = useNavigate();

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <div className="multistepform-container">
      <Formik
        initialValues={{
          modeOfLearning: "",
          durationConfirmation: "",
          feeCommitment: "",
          fullName: "",
          phoneNumber: "",
          email: "",
          streetAddress: "",
          whyDmx: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmissionStatus(""); // Reset status on new submission
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}/instaForm/dmx`, values)
            .then((response) => {
              console.log("Data submitted:", response);
              setSubmissionStatus("success");
              navigate("/instaDMXthankyou");
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              setSubmissionStatus("failed");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="instaform">
            {/* Step 1 */}
            {step === 1 && (
              <div className="stepform">
                <h3>DMX FORM</h3>
                <div className="form-field">
                  <label>Full Name</label>
                  <Field type="text" name="fullName" className="field" />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field">
                  <label>Phone number (preferably WhatsApp Number)</label>
                  <Field type="text" name="phoneNumber" className="field" />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field">
                  <label>Email</label>
                  <Field type="email" name="email" className="field" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field">
                  <label>Street Address</label>
                  <Field type="text" name="streetAddress" className="field" />
                  <ErrorMessage
                    name="streetAddress"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="singlenextinstabtn">
                  <button type="button" onClick={nextStep} disabled={isSubmitting}>
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {/* Step 2 */}
            {step === 2 && (
              <div className="stepform">
                <h3>DMX FORM</h3>
                <div className="form-field">
                  <label>What is your preferred mode of learning?</label>
                  <div className="readiofeild readiofeilddmx">
                    <Field
                      type="radio"
                      name="modeOfLearning"
                      value="Online"
                      className="readi-btn offlineradio"
                    />
                    <label className="readibtn-label radiodmxmode">Online</label>
                    <Field
                      type="radio"
                      name="modeOfLearning"
                      value="Offline"
                      className="readi-btn offlineradio"
                    />
                    <label className="readibtn-label radiodmxmode">Offline</label>
                  </div>
                  <ErrorMessage
                    name="modeOfLearning"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field">
                  <label>
                    The Boot camp duration would be 100+ days & 3 hours per day.
                    Are you ready for that?
                  </label>
                  <div className="readiofeild">
                    <Field type="radio" name="durationConfirmation" value="Yes" className="readi-btn" />
                    <label className="readibtn-label">Yes</label>
                    <Field type="radio" name="durationConfirmation" value="No" className="readi-btn" />
                    <label className="readibtn-label">No</label>
                  </div>
                  <ErrorMessage
                    name="durationConfirmation"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" /> Previous
                  </button>
                  <button type="button" onClick={nextStep} disabled={isSubmitting}>
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {/* Step 3 */}
            {step === 3 && (
              <div className="stepform">
                <h3>DMX FORM</h3>
                <div className="form-field">
                  <label>Are you comfortable with DMX bootcamp fee of 88,000?</label>
                  <div role="group" aria-labelledby="radio-group" className="selectinstaocc">
                    <label className="readiofeelabel">
                      <Field type="radio" name="feeCommitment" value="Can Afford" className="checkadiofee" />
                      Can Afford
                    </label>
                    <label className="readiofeelabel">
                      <Field type="radio" name="feeCommitment" value="OK to pay in instalments" className="checkadiofee" />
                      OK to pay in instalments
                    </label>
                    <label className="readiofeelabel">
                      <Field type="radio" name="feeCommitment" value="Cannot Afford" className="checkadiofee" />
                      Cannot Afford
                    </label>
                  </div>
                  <ErrorMessage
                    name="feeCommitment"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" /> Previous
                  </button>
                  <button type="button" onClick={nextStep} disabled={isSubmitting}>
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {/* Step 4 */}
            {step === 4 && (
              <div className="stepform">
                <h3>DMX FORM</h3>
                <div className="form-field">
                  <label>Why do you want to learn Digital Marketing?</label>
                  <Field as="textarea" name="whyDmx" className="field textareafeild" />
                  <ErrorMessage
                    name="whyDmx"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" /> Previous
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <InstaLoader /> : 
                      submissionStatus === "failed" 
                        ? "Failed to Submit" 
                        : submissionStatus === "success" 
                        ? "Submitted Form" 
                        : "Submit Form"}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepDmxform;
